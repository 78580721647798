import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component, ComponentRef, DoCheck, ElementRef, ErrorHandler, HostBinding, NgZone,
    QueryList,
    TransferState,
    ViewChild,
    ViewChildren,
    ViewContainerRef, inject,
    makeStateKey
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, NavigationStart } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import Bugsnag from '@bugsnag/js';
import { AnalyticsService } from '@tytapp/analytics';
import { Requester } from '@tytapp/api/requester';
import { AccountAlertsService } from '@tytapp/app/account-alerts.service';
import { ApiMiddlewareService, ApiReuse, ApiStateTransfer } from '@tytapp/app/middleware';
import { TYTBantaAuthentication } from '@tytapp/chat';
import { DevToolsService, HostApi, LoggerService, NavigateMessage, PlatformCapabilitiesMessage, VersionService } from '@tytapp/common';
import { TermsAcceptanceService } from '@tytapp/common/terms-acceptance.service';
import { environment } from '@tytapp/environment';
import { OfflineError, isClientSide, isOffline, isOnline, isServerSide } from '@tytapp/environment-utils';
import { Playback, PlayerComponent } from '@tytapp/media-playback';
import { filter } from 'rxjs/operators';
import { ApiAppConfig, ApiConfiguration, ApiUser, AppApi } from '../api';
import {
    AppConfig, BaseComponent, Cache, ConfirmationDialogComponent, DialogComponent, Head, MessageDialogComponent,
    OpenGraph, OpenGraphMetadata, RequestParams, RetryFailure, Shell, SiteWideAlert, SkipModelInit, UserAgent, buildQuery, sleep
} from '../common';
import { REQUEST } from '../express.tokens';
import { PrivacyPolicyService, UserService } from '../user';
import { AppErrorHandler } from './error-handler';


import { BillingService } from '@tytapp/billing';
import { NotificationsService } from '@tytapp/notifications';
import './dialog-registry';

import type * as express from '../common/express-ssr';
import { AcceptTermsComponent } from '@tytapp/common-ui';
import { UpgradeDialogComponent } from './upgrade-dialog.component';

const APP_STATUS_KEY = makeStateKey<ApiAppConfig>('app-status');
interface IpifyResult {
    ip: string;
}

@SkipModelInit()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [Head]
})
export class AppComponent extends BaseComponent implements DoCheck, AfterViewInit {
    private errorHandler = <AppErrorHandler>inject(ErrorHandler);
    private swUpdate = inject(SwUpdate, { optional: true });
    private requestParams = inject(RequestParams);
    private request = inject<express.ExpressRequest>(REQUEST, { optional: true });
    private userService = inject(UserService);
    private location = inject(Location);
    private apiConfig = inject(ApiConfiguration);
    private transferState = inject(TransferState);
    private notifications = inject(NotificationsService);
    private opengraph = inject(OpenGraph);
    private userAgent = inject(UserAgent);
    public appConfig = inject(AppConfig);
    private http = inject(HttpClient);
    private playback = inject(Playback);
    private appApi = inject(AppApi);
    private hostApi = inject(HostApi);
    private privacyPolicy = inject(PrivacyPolicyService);
    private billing = inject(BillingService);
    private accountAlerts = inject(AccountAlertsService);
    private snackBar = inject(MatSnackBar);
    private devTools = inject(DevToolsService);
    private apiStateTransfer = inject(ApiStateTransfer);
    private apiMiddleware = inject(ApiMiddlewareService);
    private apiRequester = inject(Requester);
    private apiReuse = inject(ApiReuse);
    private ngZone = inject(NgZone);
    private analytics = inject(AnalyticsService);
    private terms = inject(TermsAcceptanceService);
    private version = inject(VersionService);

    protected logger = inject(LoggerService).configure({ source: `appcomp` });

    setupMiddleware() {

        // Refuse to attempt requests while in an offline state.

        if (isClientSide()) {
            this.apiMiddleware.install((request, next) => {
                if (isOffline()) {
                    this.logger.warning(`[API] Offline: ${request.method} ${request.path}?${buildQuery(request.query)}`);
                    throw new OfflineError(`API call: ${request.method} ${request.path}?${buildQuery(request.query)}`);
                }

                return next(request);
            });
        }

        this.apiStateTransfer.install();
        this.apiReuse.install();
    }

    constructor() {
        super();

        inject(TYTBantaAuthentication).init();

        this.terms.init();
        this.terms.requestUserAccept.subscribe(() => {
            if (!('no-terms' in this.router.routerState.snapshot.root.queryParams)) {
                this.shell.showDialog(AcceptTermsComponent);
            }
        })

        this.apiConfig.globalHeaders ??= {};

        if (this.request) {
            this.apiConfig.globalHeaders['X-User-Agent'] = this.request.header('user-agent') ?? 'X-TYT-Not-Present';
            this.apiConfig.globalHeaders['X-TYT-On-Behalf-Of'] = this.request.ip ?? 'X-TYT-Not-Present';
            if (Zone.current.get('requestId'))
                this.apiConfig.globalHeaders['X-TYT-Trace'] = Zone.current.get('requestId') ?? 'X-TYT-None';
            this.apiConfig.globalHeaders['X-User-Agent'] = this.request.header('user-agent') ?? 'X-TYT-Not-Present';
        }

        this.apiRequester.logInfo = message => {
            if (isClientSide() && localStorage['tyt:api-logging'] !== 'quiet')
                this.logger.info(message);
        };
        this.apiRequester.logWarning = message => this.logger.warning(message);
        this.apiRequester.logError = message => this.logger.error(message);

        this.setupMiddleware();

        Bugsnag.addMetadata('Environment', 'side', isClientSide() ? 'client' : 'server');

        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                this.announce(`Loading...`, 2000);
                this.head.clearCanonicalLink();
                this.networkErrorType = null;
                if (isClientSide())
                    this.logger.info(`Navigating to: ${ev.url}`);
            } else if (ev instanceof NavigationEnd) {
                if (this.router.routerState.snapshot.root.queryParams['chromeless'] === '1') {
                    this.globalNoLayout = true;
                }
                if (this.router.routerState.snapshot.root.queryParams['transparentBg'] === '1') {
                    this.transparentBg = true;
                }

                this.noRoute = false;

                if (isClientSide()) {
                    let hash = window.location.hash;
                    if (hash && window.location.pathname.includes('faq')) {
                        setTimeout(() => {
                            let element = document.getElementById(hash.slice(1));
                            if (element) {
                                let wrapper = document.documentElement;
                                let count = element.offsetTop + 256;
                                wrapper.scrollBy({ top: count, left: 0 });
                            }
                        }, 1000);
                    } else {
                        window.scrollTo({ top: 0 });
                    }
                }
            }
        });

        this.shell.titleChanged.subscribe(title => {
            this.announce(`New page: ${title}`);
        });

        this.markUserAgent();
    }

    noRoute = true;

    announcementTimeout;
    announce(message: string, delay = 100) {
        setTimeout(() => {
            this.politeAnnouncement = '';
            clearTimeout(this.announcementTimeout);
            this.announcementTimeout = setTimeout(() => {
                this.politeAnnouncement = message;
            }, delay);
        })
    }

    private appConfigCache = Cache.shared<ApiAppConfig>('appconfig', 1000 * 60 * 5, 10);

    get environment() {
        return environment;
    }

    private _isDemo = null;
    get isDemo() {
        if (this._isDemo !== null)
            return this._isDemo;

        if (isClientSide()) {
            this._isDemo = (window.localStorage['tyt.content_mode'] == 'demo');
        }

        return this._isDemo || false;
    }

    set isDemo(value: boolean) {
        this._isDemo = value;
        if (isClientSide()) {
            window.localStorage['tyt.content_mode'] = value ? 'demo' : 'production';
            window.location.reload();
        }

        Bugsnag.addMetadata('Environment', 'isDemo', value ? 'true' : 'false');
    }

    doCheckCounter = 0;

    /**
     * BEACHBALL PROBLEM IN SAFARI? WE'VE GOT YOU COVERED! READ THIS!
     *
     * > Hello darkness my old friend....
     * > I've come to talk with you again
     * > Because a vision softly creeping
     * > Let it's seeds while I was sleeping...
     *
     * If we hit a massive amount of angular dirty checks,
     * we have likely hit an Angular change detection loop which will
     * crash the application. If this happens, engineer can set a breakpoint
     * on the setDebugPointHere line to diagnose the situation.
     */
    public ngDoCheck() {

        this.scheduleMainContentPositionUpdate();

        // PROTECTION AGAINST CHANGE LOOPS (beachball):

        if (!environment.showDevTools)
            return;

        if (!this._enableAngularChangeLoopDetection)
            return;

        this.doCheckCounter += 1;

        if (this.doCheckCounter > 30000) {
            if (this.doCheckCounter == 30000)
                this.logger.error(`Angular has reached a high amount of change detection cycles. This is indicative of a change detection loop.`);

            let setDebugPointHere = 1;

            // If you can't get the debugger open to set the breakpoint when the
            // issue occurs, comment out the following line.

            debugger;
        }
    }

    private mainContentPositionUpdateTimeout;
    private knownMainContentSize: number;

    /**
     * Relay the computed top position of the <main> element into the `--tyt-page-top` CSS variable so it can be used
     * for properly positioning fixed position elements.
     */
    private scheduleMainContentPositionUpdate() {
        if (!isClientSide())
            return;

        // WARNING: This runs from ngDoCheck(), which means it is VERY BAD to do an asynchronous operation from here!
        // If you do, a new dirty check will occur after the async operation completes, which will cause a new async
        // operation, etc!
        //
        // TREAD WITH CAUTION.

        this.ngZone.runOutsideAngular(() => {
            clearTimeout(this.mainContentPositionUpdateTimeout);
            this.mainContentPositionUpdateTimeout = setTimeout(() => {
                let top: number = undefined;

                if (this.mainHeader) {
                    let element = this.mainHeader.nativeElement;
                    top = element.clientTop + element.clientHeight;
                } else if (this.noLayout) {
                    top = 0;
                }

                if (top !== undefined && top !== this.knownMainContentSize) {
                    this.knownMainContentSize = top;
                    document.documentElement.style.setProperty('--tyt-page-top', `${top}px`);
                }

                if (window.parent !== window) {
                    // We're in an iframe, send size updates.

                }
            }, 100);
        });
    }

    private _enableAngularChangeLoopDetection = false;

    private _noLayout: boolean = false;

    public isChrome: boolean = false;
    public get noLayout() {
        return this._noLayout || this.globalNoLayout;
    }

    globalNoLayout = false;

    @HostBinding('class.transparent-bg')
    transparentBg = false;

    public set noLayout(value: boolean) {
        this._noLayout = value;
    }

    public menuOpen: boolean = false;
    public appName = 'web';
    public platform: PlatformCapabilitiesMessage;


    @ViewChild('player')
    player: PlayerComponent;

    @ViewChild('mainContent') mainContent: ElementRef<HTMLDivElement>;
    @ViewChild('mainHeader') mainHeader: ElementRef<HTMLElement>;

    @ViewChildren('dialogAnchor', { read: ViewContainerRef })
    dialogAnchorQuery: QueryList<ViewContainerRef>;

    get dialogAnchor() {
        return this.dialogAnchorQuery.first;
    }

    back() {
        this.location.back();
    }

    hideDialog() {
        this.shell.hideDialog();
    }

    failedToFetchAppConfig: boolean = false;

    async fetchStatus() {
        // If we are on the client side and we have a localStorage copy of the app status, we'll get that loaded in
        // to unblock general operation of the app. Feature flag checks will still require this information to be refreshed

        if (isClientSide() && localStorage['tyt:appconfig']) {
            try {
                this.appConfig.appStatus = JSON.parse(window.localStorage['tyt:appconfig']);

                // Fire app status early. We'll proceed with pulling a newer app config and save it for the next load
                // afterwards.
                this.appConfig.fireAppStatusReady();
            } catch (e) {
                this.appConfig.appStatus = null;
            }
        }

        this.errorHandler.appStatus = this.appConfig.appStatus;

        let transferredStatus = this.transferState.get<ApiAppConfig>(APP_STATUS_KEY, null);
        let ssrCacheKey = `appstatus`;
        let cachedStatus = null;
        let now = Date.now();
        let clientSide = isClientSide();
        let serverSide = !clientSide;

        if (serverSide) {
            // Try to use the global cache
            let entry = this.appConfigCache.getItem(`appstatus`);
            if (entry) {
                cachedStatus = entry.value;
                if (entry.expiresAt > now) {
                    transferredStatus = entry.value;
                    this.apiStateTransfer.prepopulate(`/app/config/${this.appName}`, { version: '1.0' }, transferredStatus);
                }
            }
        }

        if (transferredStatus) {
            this.appConfig.appStatus = transferredStatus;
        } else {

            // Fetch from the API directly

            if (isOnline()) {
                try {
                    this.appConfig.appStatus = await this.retry.standard(() => this.appApi.getStatus('1.0', this.appName).toPromise(), () => {

                        // We failed to get the app status entirely, even after several retries.
                        // If we have a cached SSR status, let's use that (even if its expired).

                        if (cachedStatus) {
                            this.appConfig.appStatus = cachedStatus;
                            this.logger.warning('Recovered from appStatus API failure by using expired value from SSR shared cache');
                        }

                        if (!this.appConfig.appStatus) {
                            this.errorHandler.handleError(
                                {
                                    errorType: 'network-error',
                                    message: 'Failed to fetch app status! The API is probably down :-( API endpoint: ' + this.apiConfig.basePath
                                }
                            );
                        }
                    });
                } catch (e) {
                    if (e instanceof RetryFailure) {
                        this.failedToFetchAppConfig = true;
                        if (!this.appConfig) {
                            this.onNetworkError(undefined);
                        }
                    } else {
                        this.logger.error(`Caught error while fetching API status: ${e}`);
                        return;
                    }
                }
            }

            if (serverSide && this.appConfig.appStatus)
                this.appConfigCache.insertItem(ssrCacheKey, this.appConfig.appStatus);

            if (serverSide)
                this.transferState.set<ApiAppConfig>(APP_STATUS_KEY, this.appConfig.appStatus);
        }

        if (this.appConfig.appStatus) {
            // Make it possible to clear the app server's persistent shared caches via a setting
            // returned by appstatus

            let cacheClearTime = this.appConfig.appStatus.settings['cache_cleared_at'];
            if (cacheClearTime && parseInt(cacheClearTime) < Date.now()) {
                Cache.clearShared();
            }

            // Allow the server to disable app-server shared caches

            let wasEnabled = Cache.getSharedCachesEnabled();
            let shouldEnable = !this.appConfig.appStatus.features.includes('apps.web.disable_shared_caches');
            if (wasEnabled != shouldEnable) {
                Cache.setSharedCachesEnabled(shouldEnable);
                if (shouldEnable)
                    this.logger.info(`App-server shared caches are now enabled.`);
                else
                    this.logger.info(`App-server shared caches are now disabled.`);
            }

            if (isClientSide() && window.localStorage)
                window.localStorage['tyt:appconfig'] = JSON.stringify(this.appConfig.appStatus);
        }

        this.appConfig.fireAppStatusReady();


        this.errorHandler.appStatus = this.appConfig.appStatus;

    }

    closePodcast() {
        if (this.player)
            this.player.dismiss();
    }

    public dialogAnimation = 'forward';

    private async dialogTransitionLock(callback: () => void | Promise<void>): Promise<void> {
        let previousPromise = this.dialogTransitionPromise;
        let myPromise = this.dialogTransitionPromise = new Promise(async (resolve, reject) => {
            await previousPromise;
            try {
                await callback();
            } catch (e) {
                reject(e);
            }
            resolve();
        });
        try {
            await this.dialogTransitionPromise;
        } finally {
            if (this.dialogTransitionPromise === myPromise) {
                this.dialogTransitionPromise = Promise.resolve();
            }
        }
    }

    /**
     * Instantiate and show a dialog, closing any previous dialog.
     * @param componentCls
     * @param args
     */
    private async openDialog(componentCls, args): Promise<void> {
        if (!componentCls) {
            throw new Error(`No dialog class passed`);
        }

        await this.dialogTransitionLock(async () => {
            this.dialogAnimation = 'forward';
            let dialogName = Shell.getNameForDialog(componentCls);

            if (environment.showDevTools && !dialogName) {
                throw new Error(`Error: Dialog ${componentCls.name} is missing @NamedDialog().`);
            }

            if (!this._noPushState && isClientSide()) {
                if (!dialogName)
                    debugger;
                try {
                    window.history.pushState({
                        type: 'dialog',
                        dialog: dialogName,
                        args: args.map(arg => typeof arg === 'function' ? undefined : arg)
                    }, '');
                } catch (e) {
                    this.logger.error(`[Dialogs] Failed to push dialog state to history API:`);
                    this.logger.error(e);
                }
            }
            this._noPushState = false;

            if (typeof document !== 'undefined') {
                document.documentElement.style.overflow = 'hidden';
            }

            if (!this.dialogVisible) {
                this.dialogComponentRef?.instance?.onClosed();
                this.dialogAnchor.clear();
                this.initDialog(componentCls, args);
                this.edgeDialogResize();
                this.dialogHidden = false;
                setTimeout(() => this.dialogVisible = true);
                return;
            }

            this.switchingDialog = true;
            this.switchingDialogBack = false;

            await this.dialogTransition(500, async () => {
                this.switchingDialog = false;
                this.dialogAnchor.clear();
                this.initDialog(componentCls, args);
                this.edgeDialogResize();
                this.switchingDialogBack = true;
            });
        });
    }

    private async dialogTransition(delay: number, callback: () => void | Promise<void>) {
        return new Promise<void>((resolve, reject) => {
            clearTimeout(this.dialogTransitionTimeout);
            this.dialogTransitionTimeout = setTimeout(async () => {
                try {
                    await callback();
                } catch (e) {
                    reject(e);
                }
                resolve();
            }, delay);
        });
    }

    private dialogTransitionTimeout;

    private dialogComponentRef: ComponentRef<DialogComponent>;

    private initDialog(componentCls, args) {
        this.dialogComponentRef = this.dialogAnchor.createComponent<any>(componentCls);
        this.dialogComponentRef.instance.initArguments = args;
    }

    public switchingDialog: boolean = false;
    public switchingDialogBack: boolean = false;

    @HostBinding('class.dialog-visible')
    public dialogVisible: boolean = false;
    public dialogHidden: boolean = true;

    public dialog: string = null;
    public dialogClosable = true;

    private dialogTransitionPromise: Promise<void>;

    public async closeDialog(): Promise<void> {
        this.shell.hideDialog();
    }

    /**
     * Close the current dialog
     */
    public async handleCloseDialog(): Promise<void> {
        if (!this.dialogVisible) {
            return;
        }

        await this.dialogTransitionLock(async () => {
            if (typeof document !== 'undefined')
                document.documentElement.style.overflow = '';

            if (!this._noPushState && isClientSide())
                window.history.pushState({}, '');

            this._noPushState = false;
            this.dialogVisible = false;

            await this.dialogTransition(500, () => {
                this.dialogComponentRef?.instance?.onClosed();
                this.dialogAnchor.clear();
                this.dialogTransitionPromise = null;
                this.dialogHidden = true;
            });
        });
    }

    browser: string;
    os: string;

    markUserAgent() {
        this.browser = this.userAgent.browser;
        this.os = this.userAgent.os;

        //if (process.env.ENVIRONMENT != 'test')
        //  Logger.info(`Browser/OS identified as: ${this.browser}/${this.os}`);

        if (typeof document !== 'undefined') {
            document.documentElement.classList.add(`${this.browser}-browser`);
            document.documentElement.classList.add(`${this.os}-os`);
        }
    }

    private edgeDialogResize() {
        if (typeof document === 'undefined')
            return;

        if (this.browser != 'edge')
            return;

        let dialogEl = document.getElementById('dialog-host');
        this.logger.info(`Edge: Resizing dialog to calc(50% - ${dialogEl.offsetWidth / 2}px)`);
        dialogEl.style.left = `calc(50% - ${dialogEl.offsetWidth / 2}px)`;
    }

    @HostBinding('class.podcast-player-visible')
    get appPlayingAudio() {
        if (!this.player)
            return false;

        if (!this.player.media)
            return false;

        let session = this.player.getCurrentPlaybackSession();
        if (session && session.videoDisabled)
            return true;

        return (this.player.assetType == 'podcast');
    }

    initKeys() {
        if (!isClientSide())
            return;

        document.addEventListener('keydown', ev => {
            if (this.dialogVisible && this.dialogClosable) {
                if (ev.key == 'Escape') {
                    this.closeDialog();
                }
            }
        });
    }

    public year: number = new Date().getFullYear();
    private _noPushState: boolean = false;

    ngAfterViewInit() {
        if (this.player)
            this.playback.appPlayer = this.player;
    }

    private _offline = false;

    get offline() {
        return this._offline;
    }

    set offline(value) {
        this._offline = value;

        if (value) {
            this.shell.addAlert({
                id: 'offline',
                type: 'warning',
                message: 'Network offline, check your Internet connection',
                internal: true,
                url: '/offline'
            });
        } else {
            this.shell.removeAlert('offline');
        }
    }
    get networkError() {
        return this.networkErrorType !== null;
    }

    networkErrorType: 'offline' | 'serverDown' | 'networkError' = null;

    alerts: SiteWideAlert[];
    opengraphMetadata: OpenGraphMetadata;

    get isServer() { return isServerSide(); }
    get clientSide() { return isClientSide(); }
    get webBillingEnabled() { return this.hasCap('web_billing:membership'); }
    get platformBillingEnabled() { return this.hasCap('platform_billing:membership'); }
    get billingEnabled() { return this.webBillingEnabled || this.platformBillingEnabled; }
    get downloadsEnabled() { return this.appConfig.featureEnabledSync('apps.web.enable_downloads'); }

    async init() {
        this.installDevTools();

        if (isClientSide()) {
            window['ngZone'] = this.ngZone;
        }

        this.subscribe(this.shell.dialogChanged, value => this.onDialogChanged(value));
        this.subscribe(this.errorHandler.networkError, (error) => this.onNetworkError(error));
        this.subscribe(this.shell.noLayoutChanged, value => this.noLayout = value);
        this.subscribe(this.shell.isChromeChanged, value => this.isChrome = value);
        this.subscribe(this.shell.dialogClosableChanged, value => this.dialogClosable = value);
        this.subscribe(this.userService.userChanged, user => this.user = user);
        this.shell.alertsChanged.subscribe(alerts => this.alerts = alerts);
        this.subscribe(this.privacyPolicy.updated, ({ lastUpdated }) => this.onPrivacyPolicyUpdated(lastUpdated));

        // Initialize auxillary services

        await this.setupHostApi();

        await Promise.all([
            this.handleUpdate(),
            this.fetchStatus(),
            this.userService.init(),
            this.analytics.init(),
            this.handleErrorFromServer(),
            this.setupSiteWideAlert(),
            this.privacyPolicy.init(),
            this.billing.init(),
            this.accountAlerts.init(),
            this.setupUnbouncePopups(),
            this.notifications.init(),
            this.setupClient(),
            this.setupClientHints(),
            this.setupVersionNags()
        ]);
    }

    private async onPrivacyPolicyUpdated(lastUpdated: Date) {
        if (false && await this.shell.hasFeature('apps.web.enable_notifications')) {
            this.notifications.add({
                type: 'local',
                id: 6,
                text: 'Privacy Policy updated',
                description: "We've updated our privacy policy. Click for details",
                timestamp: lastUpdated,
                icon: 'policy',
                url: `${environment.urls.accounts}/privacy`,
                open_in_new_tab: true,
                onDismiss: () => this.privacyPolicy.dismiss(),
                demoOnly: true
            });
        } else {
            this.shell.addAlert({
                message: "We've updated our privacy policy. Click for details",
                id: 'privacy-policy',
                type: 'info',
                url: `${environment.urls.accounts}/privacy`,
                onDismiss: () => this.privacyPolicy.dismiss()
            });
        }
    }

    private async setupClient() {
        if (!isClientSide())
            return;

        this.showConsoleGreeting();
        this.opengraph.metadataChanged.subscribe(m => this.opengraphMetadata = m);

        this.offline = isOffline();
        window.addEventListener("online", () => this.offline = false);
        window.addEventListener("offline", () => this.offline = true);
        window.addEventListener('popstate', async ev => {
            let state = ev.state;
            if (state?.type === 'dialog') {
                this._noPushState = true;
                this.shell.showDialog(await Shell.getDialogByName(state.dialog), ...(state.args ?? []));
            } else if (state?.type === 'notifications') {
                if (this.notifications.visible) {
                    this.notifications.visible = false;
                    window.history.pushState({}, '');
                }
            } else {
                if (this.dialogVisible) {
                    this._noPushState = true;

                    if (ev.state) {
                        this.shell.hideDialog();
                    }
                }
            }
        });

        this.initKeys();
    }

    private async setupClientHints() {
        // This instructs the UA to send the Sec-CH-Width and Sec-CH-Viewport-Width headers when speaking
        // with Platform. This is useful for the Images API to support dynamic image resizing for SSR.

        this.meta.addTag({
            httpEquiv: 'delegate-ch',
            content: `sec-ch-width ${environment.urls.platform}; sec-ch-viewport-width ${environment.urls.platform}`
        });
    }

    private async onDialogChanged(value) {
        if (value == null)
            this.handleCloseDialog();
        else
            this.openDialog(value[0], value[1]);

        //this.dialog = value;
    }

    get state() {
        if (this.outdated && !isServerSide() && !this.bypassOutdatedBuild(this.location.path()))
            return 'outdated';

        return 'ready';
    }

    outdated = false;
    product = environment.productName;
    isNativeBuild = environment.isNativeBuild;

    bypassOutdatedBuild(path: string) {
        if (isServerSide())
            return true;

        if (path.startsWith('/engineering/'))
            return true;

        return false;
    }

    private async setupVersionNags() {
        if (isServerSide())
            return;

        await this.version.ready;

        this.logger.info(`Version: ${this.version.current}, min: ${this.version.min}, preferred: ${this.version.preferred}`);

        if (this.version.isOutdated) {

            // If we are not native but we are outdated, then there's probably a problem with the service worker.
            // We want to be careful to not cause a redirection loop though, in case there's a problem with the server
            // side configuration, so we'll perform a single service worker reinstallation, and if that doesn't fix it,
            // we'll just have to complain.

            if (!environment.isNativeBuild) {
                if (localStorage['tyt:previous:version'] !== this.version.current) {
                    localStorage['tyt:previous:version'] = this.version.current;
                    location.assign('/--reinstall');
                    return;
                } else {
                    console.warn(`Detected outdated non-native version (${this.version.current}) but reinstallation did not resolve the problem!`);
                }
            }

            this.outdated = true;
            return;
        }

        if (this.isNativeBuild && this.version.preferredUpdateAvailable) {
            this.shell.showDialog(UpgradeDialogComponent);
        }
    }

    private async setupSiteWideAlert() {
        await this.appConfig.appStatusReady;
        if (this.appConfig.appStatus?.settings?.site_alert_msg) {
            this.shell.addAlert({
                id: 'notice',
                type: this.appConfig.appStatus.settings.site_alert_type || 'info',
                message: this.appConfig.appStatus.settings.site_alert_msg,
                url: this.appConfig.appStatus.settings.site_alert_url,
                backgroundColor: this.appConfig.appStatus.settings.site_alert_background_color,
                textColor: this.appConfig.appStatus.settings.site_alert_text_color,
            });
        }
    }

    private async setupHostApi() {
        let platformCaps;
        try {
            platformCaps = await this.hostApi.handshake();
        } catch (e) {
            this.logger.error(`Failed to connect to the application host:`);
            this.logger.error(e);
        }

        this.platform = platformCaps;

        if (platformCaps) {
            this.logger.info(`Received platform handshake for '${platformCaps.platform}':`);
            this.logger.inspect(platformCaps);
            this.appName = `${platformCaps.platform}_web`;
        }

        this.hostApi.messageReceived.pipe(filter(x => x.type === 'navigate'))
            .subscribe((m: NavigateMessage) => this.router.navigateByUrl(m.url));
    }

    private isUnbounceSetup = false;

    private async setupUnbouncePopups() {
        if (!isClientSide())
            return;

        this.userService.ready.then(() => {
            if (this.userService.user)
                return;

            if (this.isUnbounceSetup)
                return;

            this.isUnbounceSetup = true;
            let scriptElement = document.createElement('script');
            scriptElement.src = 'https://f25f6eae692848909b12eabf517233e8.js.ubembed.com';
            scriptElement.async = true;
            document.body.appendChild(scriptElement);
        });
    }

    updating = false;

    private async showConsoleGreeting() {
        if (!isClientSide())
            return;

        await sleep(1000);
        await this.logger.clientLogImage(`${location.origin}/assets/tytcom.svg`);
        this.logger.clientLogWithStyles(`%cDon't paste anything here!`, 'font-size: 38pt; color: orange; margin-top: 1em;');
        this.logger.clientLogWithStyles('%cIf someone told you to come here and paste something, do not do it! They are trying to trick you into compromising your account.', 'font-size: 12pt; margin-bottom: 3em;');
    }

    private async handleUpdate() {
        if (environment.isNativeBuild) {
            this.updating = true;
        }

        if (this.swUpdate.isEnabled) {
            try {
                this.swUpdate.unrecoverable.subscribe(() => {
                    Bugsnag.notify(new Error(`The service worker state was unrecoverable. A reload was forced.`));
                    this.logger.error(`[Updater] The service worker state was unrecoverable. A reload was forced.`);
                    window.location.reload();
                });

                if (await this.swUpdate.checkForUpdate()) {
                    this.logger.info(`[Updater] A new version of TYT.com is ready. Activating...`);

                    if (environment.isNativeBuild) {
                        this.updating = true;
                    } else {
                        this.snackBar.open(`TYT.com is being updated. You can continue to use the app as usual.`, undefined, {
                            duration: 5000
                        });
                    }

                    await this.swUpdate.activateUpdate();
                    this.logger.info(`[Updater] Done updating TYT.com. Reload the page or start a new tab to see the new version.`);

                    if (environment.isNativeBuild) {
                        window.location.reload();
                        return;
                    } else {
                        let snackbarRef = this.snackBar.open(
                            `An update for ${environment.productName} is ready. Refresh the page to start using it.`,
                            'Update Now',
                            {
                                duration: 10000
                            }
                        );
                        snackbarRef.onAction().subscribe(() => window.location.reload());
                    }

                    this.shell.updateAvailable.next(true);
                } else {
                    this.logger.info(`[Updater] No update available.`); // x
                    this.shell.updateAvailable.next(false);
                }
            } catch (e) {
                this.updating = false;
                throw e;
            }
        }

        this.updating = false;
        this.shell.updateAvailable.next(false);
    }

    private installDevTools() {
        this.devTools.rootMenu.items.push(
            {
                type: 'menu',
                id: 'onboarding',
                label: 'Onboarding',
                icon: 'play_circle',
                items: [
                    {
                        type: 'action',
                        label: 'Yes',
                        handler: (item, injector) => injector.get(UserService)
                            .onboarding = true
                    },
                    {
                        type: 'action',
                        label: 'No',
                        handler: (item, injector) => injector.get(UserService)
                            .onboarding = false
                    }
                ]
            }, {
            type: 'menu',
            icon: 'chat_bubble',
            label: 'Dialogs',
            items: [
                {
                    type: 'action',
                    label: 'Show message dialog',
                    icon: 'chat_bubble',
                    handler(item, injector) {
                        injector.get(Shell).showDialog(
                            MessageDialogComponent,
                            "This is a message!", "This is the description of the message."
                        );
                    }
                },
                {
                    type: 'action',
                    label: 'Show confirm dialog',
                    icon: 'chat_bubble',
                    handler(item, injector) {
                        injector.get(Shell).showDialog(
                            ConfirmationDialogComponent,
                            "Do you want to continue?", confirmed => alert(`You said: ${confirmed ? 'Yes' : 'No'}`)
                        );
                    }
                },
                {
                    type: 'action',
                    label: 'Show native alert dialog',
                    icon: 'chat_bubble',
                    handler: () => alert(`Here is an alert dialog`)
                },
                {
                    type: 'action',
                    label: 'Show native prompt dialog',
                    icon: 'chat_bubble',
                    handler: () => alert(`You said: '${prompt(`Enter some text:`)}'`)
                },
                {
                    type: 'action',
                    label: 'Show native confirmation dialog',
                    icon: 'chat_bubble',
                    handler: () => alert(
                        `You said you *${confirm(`Do you want to do nothing?`)
                            ? 'DID' : 'DID NOT'}* want to do nothing.`)
                },
            ]
        }, {
            type: 'menu',
            icon: 'campaign',
            label: 'Site-wide Alerts',
            items: [
                {
                    type: 'action',
                    label: 'Test site-wide alert',
                    icon: 'campaign',
                    handler(item, injector) {
                        let timestamp = new Date().toString();
                        injector.get(Shell).addAlert({
                            id: `test-${Date.now()}`,
                            message: `This is a test! The time is ${timestamp}`,
                            handler() {
                                alert(`You have clicked the alert with timestamp '${timestamp}'!`);
                            },
                        });
                    }
                },
            ]
        }
        );
    }

    offlineErrorCause: string;

    async onNetworkError(error: any) {
        this.offlineErrorCause = 'No cause was recorded.';
        if (error instanceof OfflineError) {
            this.networkErrorType = 'offline';
            this.offlineErrorCause = error.cause ?? 'No reason was recorded.';
            return;
        }

        if (this.offline) {
            this.networkErrorType = 'offline';
            return;
        }

        try {
            let data = await this.http.get<IpifyResult>('https://api.ipify.org?format=json').toPromise();
            if (data.ip) {
                this.networkErrorType = 'serverDown';
            }
        } catch (e) {
            this.networkErrorType = 'networkError';
            // Our assumption was right, network is down.
        }
    }

    user: ApiUser = null;

    private handleErrorFromServer() {
        if (this.requestParams.params.server_error)
            this.shell.showDialog(MessageDialogComponent, 'Error', this.requestParams.params.server_error);
    }

    get showDevTools() {
        return environment.showDevTools;
    }

    overrideApi(mode: 'staging' | 'production' | 'tytdev' | '') {
        if (isServerSide())
            return;

        localStorage.setItem('tyt-api-override', mode);
        window.location.reload();
    }

    skipToContent(event: Event) {
        this.logger.info(`Skipping to content`);
        event.preventDefault();
        event.stopPropagation();

        let container: HTMLElement = document.querySelector('.x-main-content');
        if (!container)
            container = document.querySelector('#main-content');

        if (container) {
            container.focus();
            container.scrollIntoView();
        } else {
            this.logger.warning(`Could not find main content to jump to!`);
        }
    }

    politeAnnouncement: string = '';

    private hasCap(cap: string) {
        return this.hostApi.capabilities.includes(cap);
    }
}
