
/**
 *   _______  ___    ___  ______
 *   \_    _\ \  \  /  / /_   _/
 *     |  |    \  \/  /   |  |      TYT.com
 *     |  |     \    /    |  |      © 2017-2024 The Young Turks, Inc.
 *     |  |      |  |     |  |
 *      \_|      |  |     |_/
 *                \/
 *
 */

import 'reflect-metadata';

// Looking for stability / macrotask issues? Use this:
//import '../node_modules/zone.js/fesm2015/task-tracking';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@tytapp/environment';
import Bugsnag from '@bugsnag/js';
import { isClientSide } from '@tytapp/environment-utils';
import './ios-host-shim';

let enableProduction = environment.angularProductionMode;
if (isClientSide() && localStorage['tyt:disableAngularProduction'] !== '1')
    enableProduction = false;

if (enableProduction) {
    enableProdMode();
}

// Try to capture complete stack traces. Useful for Bugsnag/observability as well as in general.
if ('stackTraceLimit' in Error)
    Error.stackTraceLimit = 1000;

Bugsnag.start({
    apiKey: environment.bugsnagKey,
    releaseStage: environment.name,
    appVersion: environment.version,
    appType: environment.isNativeBuild ? 'native' : (typeof window === 'undefined') ? 'ssr' : 'browser'
});

async function uninstallServiceWorkers() {
    try {
        let registrations = await navigator.serviceWorker.getRegistrations();
        let count = 0;
        for (let registration of registrations) {
            registration.unregister();
            count += 1;
        }
        console.log(`Uninstalled ${count} service workers.`);
    } catch (e) {
        console.error(`Failed to force uninstall service worker: ${e.message}`);
        console.error(e);
    }
}

function showBootstrapError() {
    let failedToBootstrap = document.querySelector('#failed-to-bootstrap') as HTMLElement;
    if (failedToBootstrap) {
        failedToBootstrap.style.display = 'block';
    }
}

async function boot() {
    if (location.pathname === '/--reinstall') {
        console.warn(`Reset requested, fixing it!`);
        await uninstallServiceWorkers();
        location.assign('/');
        return;
    }

    try {
        if (location.pathname === '/--crash') {
            throw new Error(`This is a test of the bootstrap failure trampoline.`);
        }

        await platformBrowserDynamic().bootstrapModule(AppModule, {
            //providers: [ { provide: APMService, useClass: APMServiceBrowser, deps: [] } ]
        });

        let failedToBootstrap = document.querySelector('#failed-to-bootstrap') as HTMLElement;
        if (failedToBootstrap) {
            failedToBootstrap.remove();
        }
    } catch (e) {
        console.error(`Failed to bootstrap app: ${e.message}`);
        console.error(e);
        Bugsnag.notify(e);

        console.error(`Activating trampoline!`);

        // Uninstall all service workers to ensure the user can update once this is fixed.
        await uninstallServiceWorkers();
        await showBootstrapError();
    }
}

boot();